import React from "react";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import lottie from "lottie-web";
import lottieSliderData from "../../../lotties/Homepage/slider.json";

import "./SubpageHero.scss";

class SubpageHero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      subtitle: this.props.subtitle,
      image: this.props.image
    };
  }

  componentDidMount() {
    this.setupLotties();
    gsap.registerPlugin(ScrollTrigger);

    gsap
      .timeline({
        scrollTrigger: {
          trigger: this.image,
          start: "top 70%",
          end: "top 0px",
          scrub: true
        }
      })
      .from(this.image, {
        scale: 0.8,
        ease: "none",
        borderRadius: "24px"
      })
      .to(this.image, {
        scale: 1,
        borderRadius: "0px",
        duration: 0.1,
        delay: 0.001
      });

    gsap.set([this.title, this.subtitle], {
      y: 50,
      opacity: 0
    });
    gsap.to(this.title, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.2,
      duration: 0.2,
      ease: "power3"
    });
    gsap.to(this.subtitle, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.4,
      duration: 0.5,
      ease: "power3"
    });
    gsap.to(this.slider, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.6,
      duration: 0.5,
      ease: "power3"
    });
  }

  setupLotties = () => {
    this.sliderLottie = lottie.loadAnimation({
      container: this.slider,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: lottieSliderData
    });
  };

  render() {
    return (
      <div className="subpageHero">
        <div className="subpageHero_cont">
          <h1 ref={(ref) => (this.title = ref)}>{this.state.title}</h1>
          <p ref={(ref) => (this.subtitle = ref)}>{this.state.subtitle}</p>
          <div ref={(ref) => (this.slider = ref)} className="subpageHero_cont_sliderLottie"></div>
        </div>
        <div className="subpageHero_imageCont">
          <img
            alt={"aircrex clever people"}
            ref={(ref) => (this.image = ref)}
            src={this.state.image}
            className="subpageHero_imageCont_image"
          />
        </div>
      </div>
    );
  }
}
export default SubpageHero;
