import * as React from "react";
import Layout from "../components/layout";
import Hero from "../containers/HomePage/Hero/Hero";
import ColorfulCTAComponent from "../containers/Common/ColorfulCTAComponent/ColorfulCTAComponent";
import { StickyContainer } from "react-sticky";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import image from "../images/customer/HeroImg.webp";
import SubpageHero from "../containers/Common/SubpageHero/SubpageHero";
import FunctionsSection from "../containers/Customer/FunctionsSection/FunctionsSection";

// markup
const Customer = () => {
  const intl = useIntl();
  return (
    <StickyContainer>
      <Layout>
        <SubpageHero
          title={intl.formatMessage({ id: "Customer.Hero.Title" })}
          subtitle={intl.formatMessage({ id: "Customer.Hero.Subtitle" })}
          image={image}
        />
        <FunctionsSection />
        <ColorfulCTAComponent
          title={intl.formatMessage({ id: "Customer.Signup.Title" })}
          subtitle={intl.formatMessage({ id: "Customer.Signup.Subtitle" })}
          button={intl.formatMessage({ id: "Customer.Signup.Button" })}
          link={"https://merchant.helloclever.co/enter-email"}
          isAppDownload={true}
        />
      </Layout>
    </StickyContainer>
  );
};

export default Customer;
